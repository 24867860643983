import useSWR from 'swr'
import useAccounts from '../../common/hooks/useAccounts'
import useApi from '../../common/hooks/useApi'
import {
  type OrderRequest,
  type OrderRequestsApiUpdateOrderRequestRequest,
  type OrderRequestResource,
  type OrderRequestsApiCancelOrderRequestRequest,
  type OrderRequestsApiRejectOrderRequestRequest
} from '@amici/myamici-api-client'
import useSWRMutation from 'swr/mutation'
import { type OrderRequestsApiApproveOrderRequestRequest } from '@amici/myamici-api-client/api/order-requests-api'

interface UseOrderRequestHook {
  data?: OrderRequestResource
  isLoading: boolean
  isUpdating: boolean
  isCancelling: boolean
  isApproving: boolean
  isRejecting: boolean
  update: (
    orderRequest: OrderRequest,
  ) => Promise<OrderRequestResource | undefined>
  cancel: (reason: string) => Promise<void>
  approve: (response: string) => Promise<void>
  reject: (reason: string) => Promise<void>
}

function useOrderRequest (orderRequestId: string = ''): UseOrderRequestHook {
  const { activeAccount } = useAccounts()
  const {
    orderRequestsApi: {
      getOrderRequest,
      updateOrderRequest,
      cancelOrderRequest,
      approveOrderRequest,
      rejectOrderRequest
    },
    fetcher
  } = useApi()

  const accountId = activeAccount?.accountId ?? ''

  const { data, isLoading } = useSWR<OrderRequestResource, Error>(
    accountId && orderRequestId
      ? ['order-request', orderRequestId, accountId]
      : null,
    async () => await fetcher(getOrderRequest, { orderRequestId, accountId })
  )

  const { trigger: triggerUpdate, isMutating: isUpdating } = useSWRMutation<
  OrderRequestResource,
  Error,
  ['order-request', string, string] | null,
  OrderRequestsApiUpdateOrderRequestRequest
  >(
    accountId ? ['order-request', orderRequestId, accountId] : null,
    async (_, { arg }) => await fetcher(updateOrderRequest, arg),
    { populateCache: true, revalidate: false }
  )

  const { trigger: triggerCancel, isMutating: isCancelling } = useSWRMutation(
    accountId ? ['order-request', orderRequestId, accountId] : null,
    async (_, { arg }: { arg: OrderRequestsApiCancelOrderRequestRequest }) => {
      await fetcher(cancelOrderRequest, arg)
    },
    { populateCache: false, revalidate: true }
  )

  const { trigger: triggerApprove, isMutating: isApproving } = useSWRMutation(
    accountId ? ['order-request', orderRequestId, accountId] : null,
    async (_, { arg }: { arg: OrderRequestsApiApproveOrderRequestRequest }) => {
      await fetcher(approveOrderRequest, arg)
    },
    { populateCache: false, revalidate: true }
  )

  const { trigger: triggerReject, isMutating: isRejecting } = useSWRMutation(
    accountId ? ['order-request', orderRequestId, accountId] : null,
    async (_, { arg }: { arg: OrderRequestsApiRejectOrderRequestRequest }) => {
      await fetcher(rejectOrderRequest, arg)
    },
    { populateCache: false, revalidate: true }
  )

  const update = async (
    orderRequest: OrderRequest
  ): Promise<OrderRequestResource | undefined> => {
    if (!accountId || !orderRequestId) {
      return
    }

    return await triggerUpdate({ orderRequest, orderRequestId, accountId })
  }

  const cancel = async (reason: string): Promise<void> => {
    if (accountId && orderRequestId) {
      await triggerCancel({
        cancellationReason: { reason },
        orderRequestId,
        accountId
      })
    }
  }

  const approve = async (response: string): Promise<void> => {
    if (accountId && orderRequestId) {
      await triggerApprove({
        approvalResponse: { response },
        orderRequestId,
        accountId
      })
    }
  }

  const reject = async (reason: string): Promise<void> => {
    if (accountId && orderRequestId) {
      await triggerReject({
        rejectOrderRequestRequest: { reason },
        orderRequestId,
        accountId
      })
    }
  }

  return {
    data,
    update,
    cancel,
    approve,
    reject,
    isLoading,
    isUpdating,
    isCancelling,
    isApproving,
    isRejecting
  }
}

export default useOrderRequest
