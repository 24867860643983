import { type ReactElement, type ReactNode, useState } from 'react'
import { format } from 'date-fns'
import { Trans, useTranslation } from 'react-i18next'
import { Button } from 'react-bootstrap'
import {
  type OrderRequest,
  OrderRequestAllOfOrderStatusEnum,
  OrderRequestBasePriorityEnum
} from '@amici/myamici-api-client'
import MaPanel from '../../common/components/MaPanel'
import useAllAccounts from '../../common/hooks/useAllAccounts'
import useOrderRequestLineItems from '../hooks/useOrderRequestLineItems'
import useOrderRequestSummary from '../hooks/useOrderRequestSummary'
import styles from '../assets/scss/OrderRequest.module.scss'
import { UserPermission } from '../../common/types/user-permission'
import useAccounts from '../../common/hooks/useAccounts'
import OrderRequestStatusUpdateModal from './OrderRequestStatusUpdateModal'
import useOrderRequest from '../hooks/useOrderRequest'
import useOrderRequestHistory from '../hooks/useOrderRequestHistory'
import { useToastNotification } from '../../common/components/ToastNotificationContextProvider'

function OrderRequestSummaryPanelPendingFooter ({
  orderRequest
}: {
  orderRequest: OrderRequest
}): ReactElement | undefined {
  const { t } = useTranslation()
  const { activeAccount } = useAccounts()
  const [showApproveDialog, setShowApproveDialog] = useState(false)
  const [showRejectDialog, setShowRejectDialog] = useState(false)
  const { approve, isApproving, reject, isRejecting } = useOrderRequest(
    orderRequest.id
  )
  const { mutate: refreshHistory } = useOrderRequestHistory(orderRequest.id)
  const { showToastMessage } = useToastNotification()

  const isFinanceUser = activeAccount?.permissions.some(permission =>
    [UserPermission.IsFinance, UserPermission.IsFinanceUser].includes(
      permission as UserPermission
    )
  )

  const handleError = (i18nKey: string): void => {
    showToastMessage(
      'danger',
      t(i18nKey, {
        ref: orderRequest.order_ref
      })
    )
  }

  return isFinanceUser
    ? (
    <>
      <Button
        variant="danger"
        className="rounded"
        onClick={() => {
          setShowRejectDialog(true)
        }}
      >
        <Trans i18nKey="order_request.action.reject" />
      </Button>
      <Button
        className="rounded"
        onClick={() => {
          setShowApproveDialog(true)
        }}
      >
        <Trans i18nKey="order_request.action.approve" />
      </Button>
      <OrderRequestStatusUpdateModal
        show={showApproveDialog}
        onClose={() => {
          setShowApproveDialog(false)
        }}
        onConfirm={approvalResponse => {
          void approve(approvalResponse)
            .then(() => {
              void refreshHistory()
            })
            .catch(() => {
              handleError('order_request.status_update.approve.failure')
            })
            .finally(() => {
              setShowApproveDialog(false)
            })
        }}
        disabled={isApproving}
        confirmLabel={t('order_request.action.approve')}
        title={t('order_request.status_update.approve.title', {
          ref: orderRequest.order_ref
        })}
        reasonLabel={t(
          'order_request.status_update.approve.approval_response.label'
        )}
      />
      <OrderRequestStatusUpdateModal
        show={showRejectDialog}
        onClose={() => {
          setShowRejectDialog(false)
        }}
        onConfirm={reason => {
          void reject(reason)
            .then(() => {
              void refreshHistory()
            })
            .catch(() => {
              handleError('order_request.status_update.reject.failure')
            })
            .finally(() => {
              setShowRejectDialog(false)
            })
        }}
        disabled={isRejecting}
        confirmLabel={t('order_request.action.reject')}
        title={t('order_request.status_update.reject.title', {
          ref: orderRequest.order_ref
        })}
        reasonLabel={t(
          'order_request.status_update.reject.rejection_reason.label'
        )}
        reasonRequired
      />
    </>
      )
    : undefined
}

function OrderRequestSummaryPanelFooter ({
  orderRequest
}: {
  orderRequest: OrderRequest
}): ReactElement {
  let footerContent: ReactElement | undefined
  switch (orderRequest.order_status) {
    case OrderRequestAllOfOrderStatusEnum.NEW:
    case OrderRequestAllOfOrderStatusEnum.REJECTED:
      footerContent = (
        <Button variant="primary" className="rounded">
          <Trans i18nKey="order_request.action.request_approval" />
        </Button>
      )
      break
    case OrderRequestAllOfOrderStatusEnum.PENDING:
      footerContent = (
        <OrderRequestSummaryPanelPendingFooter orderRequest={orderRequest} />
      )
      break
  }
  return (
    <>
      {footerContent && (
        <MaPanel.Footer className={styles['summary-panel-footer']}>
          {footerContent}
        </MaPanel.Footer>
      )}
    </>
  )
}

interface OrderRequestSummaryProps {
  orderRequest: OrderRequest
}

function OrderRequestSummaryPanel ({
  orderRequest
}: OrderRequestSummaryProps): ReactNode {
  const { t } = useTranslation()
  const { allAccounts, isLoading: isLoadingAllAccounts } = useAllAccounts()
  const {
    count,
    total,
    currency,
    lineItems,
    isLoading: isLoadingLineItems
  } = useOrderRequestLineItems(orderRequest?.id)
  const { suppliers } = useOrderRequestSummary(lineItems)

  const requestedByAccount = allAccounts?.content?.find(
    account => account.id === orderRequest.requested_by?.id
  )

  if (isLoadingAllAccounts || isLoadingLineItems) {
    return null
  }

  return (
    <MaPanel className={styles['summary-panel']}>
      <MaPanel.Header className={styles['summary-panel-header']}>
        <h4>
          {t('order_request.summary.title')}{' '}
          {orderRequest.priority &&
            orderRequest.priority !== OrderRequestBasePriorityEnum.NONE && (
              <span
                className={
                  styles[`priority-${orderRequest.priority.toLowerCase()}`]
                }
              >
                {t(
                  `order_request.priority.${orderRequest.priority.toLowerCase()}`
                )}
              </span>
          )}
        </h4>
      </MaPanel.Header>

      <MaPanel.Body className={styles['summary-panel-body']}>
        <p className={styles['summary-message']}>
          {orderRequest?.confidential && (
            <span className={styles.confidential}>
              {t('order_request.type.confidential')}
            </span>
          )}
          <Trans
            i18nKey={'order_request.summary.message.requested_by'}
            values={{
              requestedBy: requestedByAccount?.name,
              createdOn: format(
                new Date(orderRequest.created_date),
                'do MMMM yyyy'
              )
            }}
          />{' '}
          <Trans
            i18nKey={'order_request.summary.message.items'}
            values={{ count }}
          />{' '}
          <Trans
            i18nKey={'order_request.summary.message.suppliers'}
            values={{ supplier: suppliers?.[0]?.name, count: suppliers.length }}
          />{' '}
          <Trans
            i18nKey={'order_request.summary.message.total_cost'}
            values={{ price: total, currency }}
          />
          {orderRequest.required_by_date && (
            <>
              {' '}
              <Trans
                i18nKey={'order_request.summary.message.required_by_date'}
                values={{
                  requestedBy: orderRequest.required_by_date,
                  requiredByDate: format(
                    new Date(orderRequest.required_by_date),
                    'do MMMM yyyy'
                  )
                }}
              />
            </>
          )}
        </p>

        {orderRequest.reason && (
          <div className={styles.reason}>
            <h6>{t('order_request.summary.reason.title')}</h6>
            <p>{orderRequest.reason}</p>
          </div>
        )}
      </MaPanel.Body>
      <OrderRequestSummaryPanelFooter orderRequest={orderRequest} />
    </MaPanel>
  )
}

export default OrderRequestSummaryPanel
