import React, {
  type ReactElement,
  type ReactNode,
  createContext,
  useState,
  useContext
} from 'react'
import { ToastContainer } from 'react-bootstrap'
import styles from '../assets/scss/MaToast.module.scss'
import { type Variant } from 'react-bootstrap/types'
import MaToast from './MaToast'

interface ToastNotificationContextProps {
  showToast: (toastId: number, toast: ReactElement) => void
  showToastMessage: (type: Variant, message: string) => number
  closeToast: (toastId: number) => void
}

export const ToastNotificationContext = createContext<
ToastNotificationContextProps | undefined
>(undefined)

export const useToastNotification = (): ToastNotificationContextProps => {
  const context = useContext(ToastNotificationContext)
  if (!context) {
    throw new Error(
      'useToastNotification must be used within a ToastNotificationContextProvider'
    )
  }
  return context
}

function ToastNotificationContextProvider ({
  children
}: {
  children: ReactNode
}): ReactElement {
  const [toastList, setToastList] = useState(new Map<number, ReactElement>())

  const showToast = (toastId: number, toast: ReactElement): void => {
    setToastList(toastList => {
      const newToastList = new Map(toastList)
      newToastList.set(toastId, toast)
      return newToastList
    })
  }

  const closeToast = (toastId: number): void => {
    setToastList(toastList => {
      const newToastList = new Map(toastList)
      newToastList.delete(toastId)
      return newToastList
    })
  }

  const showToastMessage = (type: Variant, message: string): number => {
    const toastId = Date.now()
    showToast(
      toastId,
      <MaToast
        type={type}
        onClose={() => {
          closeToast(toastId)
        }}
      >
        <span>{message}</span>
      </MaToast>
    )
    return toastId
  }

  return (
    <ToastNotificationContext.Provider
      value={{ showToast, showToastMessage, closeToast }}
    >
      {children}
      <ToastContainer className={styles['toast-container']}>
        {[...toastList.entries()].reverse().map(([key, toast]) => (
          <React.Fragment key={key}>{toast}</React.Fragment>
        ))}
      </ToastContainer>
    </ToastNotificationContext.Provider>
  )
}

export default ToastNotificationContextProvider
