import { type ReactElement } from 'react'
import { Table as BsTable } from 'react-bootstrap'
import { type LineItemResource } from '@amici/myamici-api-client'
import {
  type Table,
  type ColumnSort,
  type ColumnFilter
} from '@tanstack/react-table'
import OrderItemsTableHead from './OrderItemsTableHead'
import OrderItemsTableBody from './OrderItemsTableBody'
import styles from '../assets/scss/OrderItemsTable.module.scss'

export interface OrderItemsTableProps {
  table: Table<LineItemResource>
  canReceiveItems: boolean
  onSortingChange: (sort: ColumnSort | null) => void
  onFilterChange?: (filter: ColumnFilter) => void
  onQuickReceive?: (lineItem: LineItemResource) => Promise<void>
  onReceiveAndEdit?: (lineItem: LineItemResource) => void
}

function OrderItemsTable ({
  table,
  canReceiveItems,
  onSortingChange,
  onFilterChange,
  onQuickReceive,
  onReceiveAndEdit
}: OrderItemsTableProps): ReactElement | null {
  const { getHeaderGroups, getRowModel } = table

  const [headerGroup] = getHeaderGroups()
  const rows = getRowModel().rows

  if (rows.length < 1) {
    return null
  }

  return (
    <div className={styles['table-wrapper']}>
      <BsTable
        className={styles['order-items-table']}
        data-testid="items-table-view"
        size="sm"
        responsive
        bordered
      >
        <OrderItemsTableHead
          headers={headerGroup.headers}
          onSortingChange={onSortingChange}
          onFilterChange={onFilterChange}
        />
        <OrderItemsTableBody
          rows={rows}
          canReceiveItems={canReceiveItems}
          onQuickReceive={onQuickReceive}
          onReceiveAndEdit={onReceiveAndEdit}
        />
      </BsTable>
    </div>
  )
}

export default OrderItemsTable
