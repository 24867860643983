import MaConfirm from '../../common/components/MaConfirm'
import { type ReactElement, useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Col, Form } from 'react-bootstrap'
import styles from '../assets/scss/OrderRequestStatusUpdateModal.module.scss'
import { useForm } from 'react-hook-form'

const REASON_MAX_LENGTH = 500

interface OrderRequestStatusUpdateModalProps {
  show: boolean
  onClose: () => void
  onConfirm: (reason: string) => void
  disabled: boolean
  confirmLabel: string
  title: string
  reasonLabel: string
  reasonRequired?: boolean
}

function OrderRequestStatusUpdateModal ({
  show,
  onClose,
  onConfirm,
  disabled,
  confirmLabel,
  title,
  reasonLabel,
  reasonRequired = false
}: OrderRequestStatusUpdateModalProps): ReactElement {
  const { t } = useTranslation()
  const {
    register,
    watch,
    formState: { errors, isValid },
    reset,
    getValues,
    setValue
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    values: {
      reason: ''
    }
  })
  const reason = watch('reason').trim()

  useEffect(() => {
    reset()
  }, [reset, show])

  const handleConfirm = (): void => {
    setValue('reason', getValues('reason').trim())
    onConfirm(getValues('reason'))
  }

  return (
    <MaConfirm
      size="lg"
      show={show}
      onConfirm={handleConfirm}
      onClose={onClose}
      title={title}
      confirmLabel={confirmLabel}
      disabled={disabled || !isValid || (reasonRequired && reason.length === 0)}
    >
      <Form className={styles.form}>
        <fieldset disabled={disabled}>
          <Form.Group as={Col} controlId="reason">
            <Form.Label
              column={false}
              className={reasonRequired ? 'required' : ''}
            >
              {reasonLabel}
            </Form.Label>
            <Form.Control
              {...register('reason', {
                validate: value => {
                  return value.trim().length > REASON_MAX_LENGTH
                    ? t(
                      'validation.error.order_request.status_update.reason.max_length',
                      {
                        count: REASON_MAX_LENGTH
                      }
                    )
                    : true
                }
              })}
              as="textarea"
              rows={6}
              required={reasonRequired}
            />
            <Form.Control.Feedback
              type={errors.reason ? 'invalid' : 'valid'}
              className={styles['reason-feedback']}
            >
              <span>
                <Trans
                  i18nKey="order_request.status_update.reason.counter"
                  values={{
                    count: reason.length,
                    limit: REASON_MAX_LENGTH
                  }}
                />
              </span>
              {errors.reason && <span>{errors.reason?.message}</span>}
            </Form.Control.Feedback>
          </Form.Group>
        </fieldset>
      </Form>
    </MaConfirm>
  )
}

export default OrderRequestStatusUpdateModal
